.progressBar {
	background-color: white;
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	gap: 8px;
	padding: 15px;
	width: 100%;
}
.statusButton {
	border: none;
	border-radius: 5px;
	font-weight: 500;
	height: 30px;
	text-align: center;
	width: 200px;
}
.progressIcon {
	color: white;
	font-size: 18px;
}
