:root {
    --primaryColor: #f57301;
    --secondaryColor: #35495d;
    --secondaryDarkColor: #2c3945;
    --whiteColor: #fff;
    --lowWhiteColor: #e4e8ed;
    --lightGrayColor: #f5f6f7;
    --darkGrayColor: #3c4144;
    --darkJellyBeanColor: #3d719e;
    --darkJellyBeanColor: #3d719e;
}
