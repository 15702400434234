.header {
    display: flex;
    flex: 1;
    padding: 0.875rem 1rem 0.875rem 1rem;
    height: 4rem;
    z-index: 1000;
    width: 100%;
    position: fixed;
    background-color: white;
    align-items: center;
    box-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
}

.logo {
    width: 12.5rem;
    height: 2rem;
    margin-right: 5rem;
}
