@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3b4144;
}

/* Global css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

    /*Scrollbar*/
    *::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }


    *::-webkit-scrollbar-track {
        background: #f1f1f1;
    }


    *::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 8px;
    }


    *::-webkit-scrollbar-thumb:hover {
            background: #555;
    }

:root {
    --primaryColor: #f57301;
    --secondaryColor: #35495d;
    --secondaryDarkColor: #2c3945;
    --whiteColor: #fff;
    --lowWhiteColor: #e4e8ed;
    --lightGrayColor: #f5f6f7;
    --darkGrayColor: #3c4144;
    --darkJellyBeanColor: #3d719e;
    --darkJellyBeanColor: #3d719e;
}

.SectionHeader_sectionHeader__STY0_ {
    padding: 20px;
    background-color: var(--whiteColor);
    display: flex;
    position: relative;
}

.SectionHeader_textContainer__1TXke {
    display: flex;
    grid-gap: 20px;
    align-items: center;
    margin-right: 8px;
}

    .SectionHeader_textContainer__1TXke::after {
        order: 1;
        content: '';
        display: block;
        width: 36px;
        height: 36px;
        background-color: var(--darkJellyBeanColor);
        border-radius: 5px;
    }

.SectionHeader_text__w2U9N {
    order: 2;
    text-align: start;
}


.SimplePagination_wrapper__MNNX4 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SimplePagination_currentPage__oLB69 {
    margin: 0 16px;
}
.UpdateStaff_box__XrTkT{
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px;
    margin-bottom: 16px;
    align-items: center;
    padding: 16px;
    background-color: white;
}

.UpdateStaff_box__icon__1teE9 {
    background: rgb(42, 115, 158);
    border-radius: 6px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
}

.UpdateStaff_box__text__1-_iE {
    font-size: 18px;
    font-weight: 700;
}

.UpdateStaff_box__label__2j0Yg {
    display: block;
    flex-basis: 100%;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    padding-left: 13px;
    margin-left: -16px;
    margin-bottom: 16px;
    border-left: 3px solid rgb(0, 111, 182);
}

.UpdateStaff_box__component__3Ca8q {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin-bottom: 32px;
}

.UpdateStaff_box__component--right__1VuMf {
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 0;
}

.UpdateStaff_toggle__2w0KL {
    flex-basis: 100%;
}

.UpdateStaff_toggle__header__39L6a {
    background: #F5F6F7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
}

.UpdateStaff_toggle__icon__LXSBX {
    padding: 8px;
}

.UpdateStaff_toggle__text__3Puds {
    font-weight: 500;
}

.UpdateStaff_toggle__children__1q9-1 {
    display: flex;
    padding: 16px 8px 0px;
}

.UpdateStaff_statusbox__Gp8ib {
    flex:1 1;
    font-size: 14px;
    font-weight: 400;
    margin-right: 34px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E6ECF0;
}

.UpdateStaff_statusbox__Gp8ib:last-child{
    margin-right: 0;
}

.UpdateStaff_statusbox__label__1i4-6 {
    font-size: 12px;
}

.UpdateStaff_statusbox__link__lJL2a {
    margin-right: 24px;
}

.UpdateStaff_item__1EO5A {
    flex-basis: 100%;
    margin-right: 24px;
    margin-bottom: 8px;
}

.UpdateStaff_item__label__1ph-j {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #006FB6;
    margin-bottom: 8px;
}

.Header_header__1SZE3 {
    display: flex;
    flex: 1 1;
    padding: 0.875rem 1rem 0.875rem 1rem;
    height: 4rem;
    z-index: 1000;
    width: 100%;
    position: fixed;
    background-color: white;
    align-items: center;
    box-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
}

.Header_logo__1F623 {
    width: 12.5rem;
    height: 2rem;
    margin-right: 5rem;
}

.styles_subbox__1NUhU {
    border:1px solid #D0D6E0;
    border-radius: 6px;
    margin: 16px 0px;
}

.styles_subbox__header__3fU7n {
    background: #F5F6F7;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    font-size: 16px;
    font-weight: 700;
}

.styles_subbox__showmore__3e1Ya {
    text-align: center;
    padding: 12px;
}

.styles_link__2CT9P {
    cursor: pointer;
    color: #006FB6;
}

.styles_link--showmore__3URak {
    font-size: 14px;
    font-weight: 500;
}

.ProgressStatuses_progressBar__2nTre {
	background-color: white;
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	grid-gap: 8px;
	gap: 8px;
	padding: 15px;
	width: 100%;
}
.ProgressStatuses_statusButton__1DxlR {
	border: none;
	border-radius: 5px;
	font-weight: 500;
	height: 30px;
	text-align: center;
	width: 200px;
}
.ProgressStatuses_progressIcon__PykaI {
	color: white;
	font-size: 18px;
}

.UpdateAccount_box__2izjo{
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px;
    margin-bottom: 16px;
    align-items: center;
    padding: 16px;
    background-color: white;
}

.UpdateAccount_box__icon__1Yuk_ {
    background: rgb(42, 115, 158);
    border-radius: 6px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
}

.UpdateAccount_box__text__1MiCy {
    font-size: 18px;
    font-weight: 700;
}

.UpdateAccount_box__label__2m7Of {
    display: block;
    flex-basis: 100%;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    padding-left: 13px;
    margin-left: -16px;
    margin-bottom: 16px;
    border-left: 3px solid rgb(0, 111, 182);
}

.UpdateAccount_box__component__eHFvW {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin-bottom: 32px;
}

.UpdateAccount_box__component--right__pYsxQ {
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 0;
}

.UpdateAccount_toggle__1SYIl {
    flex-basis: 100%;
}

.UpdateAccount_toggle__header__32DfV {
    background: #F5F6F7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
}

.UpdateAccount_toggle__icon__1X_JY {
    padding: 8px;
}

.UpdateAccount_toggle__text__3PExT {
    font-weight: 500;
}

.UpdateAccount_toggle__children__1GNP0 {
    display: flex;
    padding: 16px 8px 0px;
}

.UpdateAccount_statusbox__1bXJZ {
    flex:1 1;
    font-size: 14px;
    font-weight: 400;
    margin-right: 34px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E6ECF0;
}

.UpdateAccount_statusbox__1bXJZ:last-child{
    margin-right: 0;
}

.UpdateAccount_statusbox__label__3Z_2m {
    font-size: 12px;
}

.UpdateAccount_statusbox__link__1Pm8n {
    margin-right: 24px;
}

.UpdateAccount_item__224WD {
    margin-right: 24px;
    margin-bottom: 8px;
}

.UpdateAccount_item__label__3YAFh {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #006FB6;
    margin-bottom: 8px;
}

.App_content__3La4L {
    display: flex;
    background-color: var(--lightGrayColor);
/*    max-width: 1400px;*/
    margin: auto;
}

.UpdateRole_box__33ePd{
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px;
    margin-bottom: 16px;
    align-items: center;
    padding: 16px;
    background-color: white;
}

.UpdateRole_box__icon__1mSPO {
    background: rgb(42, 115, 158);
    border-radius: 6px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
}

.UpdateRole_box__text__1Vx0f {
    font-size: 18px;
    font-weight: 700;
}

.UpdateRole_box__label__1hXvA {
    display: block;
    flex-basis: 100%;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    padding-left: 13px;
    margin-left: -16px;
    margin-bottom: 16px;
    border-left: 3px solid rgb(0, 111, 182);
}

.UpdateRole_box__component__2MK-c {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin-bottom: 32px;
}

.UpdateRole_box__component--right__1wtVO {
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 0;
}

.UpdateRole_toggle__3eehM {
    flex-basis: 100%;
}

.UpdateRole_toggle__header__hQY8P {
    background: #F5F6F7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
}

.UpdateRole_toggle__icon__2zxrs {
    padding: 8px;
}

.UpdateRole_toggle__text__2g77P {
    font-weight: 500;
}

.UpdateRole_toggle__children__11eoc {
    display: flex;
    padding: 16px 8px 0px;
}

.UpdateRole_statusbox__kymhg {
    flex:1 1;
    font-size: 14px;
    font-weight: 400;
    margin-right: 34px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E6ECF0;
}

.UpdateRole_statusbox__kymhg:last-child{
    margin-right: 0;
}

.UpdateRole_statusbox__label__2tTVB {
    font-size: 12px;
}

.UpdateRole_statusbox__link__2OWjO {
    margin-right: 24px;
}

.UpdateRole_item__3YdvM {
    margin-right: 24px;
    margin-bottom: 8px;
}

.UpdateRole_item__label__2NZk9 {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #006FB6;
    margin-bottom: 8px;
}

.UpdateFunction_box__I4nab{
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px;
    margin-bottom: 16px;
    align-items: center;
    padding: 16px;
    background-color: white;
}

.UpdateFunction_box__icon__3KYWX {
    background: rgb(42, 115, 158);
    border-radius: 6px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
}

.UpdateFunction_box__text__2DB2e {
    font-size: 18px;
    font-weight: 700;
}

.UpdateFunction_box__label__kaAN- {
    display: block;
    flex-basis: 100%;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    padding-left: 13px;
    margin-left: -16px;
    margin-bottom: 16px;
    border-left: 3px solid rgb(0, 111, 182);
}

.UpdateFunction_box__component__GNSUo {
    display: flex;
    flex-basis: 100%;
    margin-bottom: 32px;
}

.UpdateFunction_box__component--right__2BwEH {
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 0;
}

.UpdateFunction_toggle__uBkkn {
    flex-basis: 100%;
}

.UpdateFunction_toggle__header__3lha2 {
    background: #F5F6F7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
}

.UpdateFunction_toggle__icon__odHBL {
    padding: 8px;
}

.UpdateFunction_toggle__text__nwaOy {
    font-weight: 500;
}

.UpdateFunction_toggle__children__iNZnA {
    display: flex;
    padding: 16px 8px 0px;
}

.UpdateFunction_statusbox__2Ooru {
    flex:1 1;
    font-size: 14px;
    font-weight: 400;
    margin-right: 34px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E6ECF0;
}

.UpdateFunction_statusbox__2Ooru:last-child{
    margin-right: 0;
}

.UpdateFunction_statusbox__label__JQdgR {
    font-size: 12px;
}

.UpdateFunction_statusbox__link__1__AC {
    margin-right: 24px;
}

.UpdateFunction_item__1hRLJ {
    margin-right: 24px;
}

.UpdateFunction_item__label__Mu3gn {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #006FB6;
    margin-bottom: 8px;
}

.styles_header__ruKcq {
    align-items: center;
    background: #F5F6F7;
    border-radius: 6px;
    cursor: pointer;    
    display: flex;
    padding: 8px;
    margin-bottom: 16px;
    font-size: 13px;
    font-weight: 500;
}

.styles_icon__V9mhr {
    margin: 0 12px 0 4px;
}

.styles_title__13p29 {
    flex: 1 1;
}

.styles_optional__UjBP8 {

}

.styles_children__1q5NL {
    overflow: hidden;
    transition: height 0.4s linear;
}
/* * { outline: 1px dotted red } */

.styles_block__20rTC {
    box-shadow: 0px 1px 2px rgba(28, 30, 33, 0.2);
    border-radius: 12px;
    margin-bottom: 16px;
}

.styles_block--white__1u6ob {
    background-color: white;
    padding: 16px;
}

.styles_title__2UiSP{
    background: #FFFFFF;
    border-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    padding: 16px;
}

.styles_title__name__1M4Gn{
    align-items: center;
    display: flex;
    flex: 1 1;
    font-size: 18px;
    font-weight: 700;
}

.styles_title__icon__ozY5_ {
    background: #A094ED;
    border-radius: 6px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
}

.styles_title__btn__2dU6q {

}

.styles_infomation__2LLNH {
    background: #F7F8F9;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    flex-direction: row;
    padding: 16px;
}

.styles_infomation__item__1FySH {
    display: block;
    font-size: 14px;
    font-weight: 400;
}

.styles_infomation__label__2Nmvn {
    color: #657786;
    font-size: 12px;
    font-weight: 400;
}

.styles_infomation__block__2HOlM {
    margin-right: 32px;
}

.styles_infomation__item--blue__357cQ {
    color: #006FB6;
}

.styles_infomation__item--link__2mhse {
    cursor: pointer;
    color: #006FB6;
}

.styles_content__2TpEn {
    display: flex;
}

.styles_content__left__2vYZ- {
    width: calc(70% - 16px);
    margin-right: 16px;
}

.styles_content__right__23h_O {
    width: 30%;
}

.styles_separate__hGw3t {
    border-bottom: 1px solid #D0D6E0;
    margin: 16px -16px;
}

.styles_subbox__separate__1IYGz {
    border-bottom: 1px solid #D0D6E0;
}

.styles_activitiesTab__S7DAq {
    border: 1px solid #D0D6E0;
    border-radius: 6px;
}

.styles_input__2CLxV {
    margin-bottom: 16px;
}

.styles_input--right__1F_EL {
    text-align: right;
}

.styles_note__2du4J {
    font-size: 14px;
    flex-basis: 33.33%;
    margin-bottom: 16px;
    max-width: 33.33%;
    padding-right: 16px;
    word-break: break-all;
}

.styles_note--underline__mTJxp {
    border-bottom: 1px solid #E6ECF0;
    margin-bottom: 8px;
    padding-bottom: 5px;
    padding-right: 0px;
    max-width: 100%;
}

.styles_modified__o17wE {
    color: #657786;
    font-size: 12px;
    margin-bottom: 4px;
}

.styles_link__mXCHV {
    cursor: pointer;
    color: #006FB6;
}

.styles_link--note__y-Fc6 {
    font-size: 14px;
}

.styles_link--attachment__2KjiS {
    font-size: 13px;
    font-weight: 400;
}

.styles_showmore__3LR_H {
    text-align: center;
}

.styles_subbox__showmore__gCPRB {
    text-align: center;
    padding: 12px;
}

.styles_link--showmore__24OwS {
    font-size: 14px;
    font-weight: 500;
}

.styles_boxtitle__1HmHM {
    display: flex;
    justify-content: space-between;
}

.styles_boxtitle__icon__3Y89s {
    border: 1px solid #D0D6E0;
    border-radius: 4px;
    padding: 2px;
    cursor: pointer;
}

.styles_attachment__1RLa7 {
    align-items: center;
    display: flex;
    flex-basis: 33.33%;
    margin-bottom: 16px;
}

.styles_attachment__icon___JGTG {
    margin-right: 8px;
}

.styles_attachment--underline__1yWEK {
    border-bottom: 1px solid #E6ECF0;
    margin-bottom: 16px;
    padding-bottom: 5px;
}

.styles_subbox__2wWCL {
    border:1px solid #D0D6E0;
    border-radius: 6px;
    margin-bottom: 16px;
}

.styles_subbox__header__27FQy {
    background: #F5F6F7;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    font-size: 16px;
    font-weight: 700;
}

.styles_subbox__content__1ykOF {
    padding: 16px 16px 0;
    display: flex;
    flex-wrap: wrap;
}

.styles_collapsebox__Ol1kJ {
    display: flex;
    flex-wrap: wrap;    
    font-weight: 400;
    font-size: 14px;
    justify-content: space-between;
    padding: 0 8px;
}

.styles_collapsebox__item__2ofj6 {
    border-bottom: 1px solid #E6ECF0;
    flex: 1 1 50%;
    max-width: 48%;
    padding-bottom: 5px;
    margin-bottom: 16px;
}

.styles_collapsebox__item--full__24Kmm {
    border-bottom: 1px solid #E6ECF0;
    margin-bottom: 16px;
    max-width: none;
}

.styles_collapsebox__label__33F_j {
    color: #657786;
    font-size: 12px;
}

.styles_modified_time__b-Lso { 
    margin-right: 8px;
}
.styles_box__3V4u9{
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px;
    margin-bottom: 16px;
    align-items: center;
    padding: 16px;
    background-color: white;
}

.styles_box__icon__tobtU {
    background: rgb(42, 115, 158);
    border-radius: 6px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
}

.styles_box__text__QgQJL {
    font-size: 18px;
    font-weight: 700;
}

.styles_box__label__1oH4k {
    display: block;
    flex-basis: 100%;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    padding-left: 13px;
    margin-left: -16px;
    margin-bottom: 16px;
    border-left: 3px solid rgb(0, 111, 182);
}

.styles_box__component__2fnoZ {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin-bottom: 32px;
}

.styles_box__component--right__jkRSe {
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 0;
}

.styles_toggle__2fBot {
    flex-basis: 100%;
}

.styles_toggle__header__6d64x {
    background: #F5F6F7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
}

.styles_toggle__icon___tGgx {
    padding: 8px;
}

.styles_toggle__text__-wQGr {
    font-weight: 500;
}

.styles_toggle__children__9Flt3 {
    display: flex;
    padding: 16px 8px 0px;
}

.styles_statusbox__1lhUz {
    flex:1 1;
    font-size: 14px;
    font-weight: 400;
    margin-right: 34px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E6ECF0;
}

.styles_statusbox__1lhUz:last-child{
    margin-right: 0;
}

.styles_statusbox__label__IMWfI {
    font-size: 12px;
}

.styles_statusbox__link__3Devx {
    margin-right: 24px;
}

.styles_item__1p6E7 {
    margin-right: 24px;
    margin-bottom: 8px;
}

.styles_item__label__1xaM6 {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #006FB6;
    margin-bottom: 8px;
}

