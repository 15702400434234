.box{
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px;
    margin-bottom: 16px;
    align-items: center;
    padding: 16px;
    background-color: white;
}

.box__icon {
    background: rgb(42, 115, 158);
    border-radius: 6px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
}

.box__text {
    font-size: 18px;
    font-weight: 700;
}

.box__label {
    display: block;
    flex-basis: 100%;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    padding-left: 13px;
    margin-left: -16px;
    margin-bottom: 16px;
    border-left: 3px solid rgb(0, 111, 182);
}

.box__component {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin-bottom: 32px;
}

.box__component--right {
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 0;
}

.toggle {
    flex-basis: 100%;
}

.toggle__header {
    background: #F5F6F7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
}

.toggle__icon {
    padding: 8px;
}

.toggle__text {
    font-weight: 500;
}

.toggle__children {
    display: flex;
    padding: 16px 8px 0px;
}

.statusbox {
    flex:1;
    font-size: 14px;
    font-weight: 400;
    margin-right: 34px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E6ECF0;
}

.statusbox:last-child{
    margin-right: 0;
}

.statusbox__label {
    font-size: 12px;
}

.statusbox__link {
    margin-right: 24px;
}

.item {
    flex-basis: 100%;
    margin-right: 24px;
    margin-bottom: 8px;
}

.item__label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #006FB6;
    margin-bottom: 8px;
}
