@import './../../styles.css';

.sectionHeader {
    padding: 20px;
    background-color: var(--whiteColor);
    display: flex;
    position: relative;
}

.textContainer {
    display: flex;
    grid-gap: 20px;
    align-items: center;
    margin-right: 8px;
}

    .textContainer::after {
        order: 1;
        content: '';
        display: block;
        width: 36px;
        height: 36px;
        background-color: var(--darkJellyBeanColor);
        border-radius: 5px;
    }

.text {
    order: 2;
    text-align: start;
}
