.subbox {
    border:1px solid #D0D6E0;
    border-radius: 6px;
    margin: 16px 0px;
}

.subbox__header {
    background: #F5F6F7;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    font-size: 16px;
    font-weight: 700;
}

.subbox__showmore {
    text-align: center;
    padding: 12px;
}

.link {
    cursor: pointer;
    color: #006FB6;
}

.link--showmore {
    font-size: 14px;
    font-weight: 500;
}
