/* * { outline: 1px dotted red } */

.block {
    box-shadow: 0px 1px 2px rgba(28, 30, 33, 0.2);
    border-radius: 12px;
    margin-bottom: 16px;
}

.block--white {
    background-color: white;
    padding: 16px;
}

.title{
    background: #FFFFFF;
    border-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    padding: 16px;
}

.title__name{
    align-items: center;
    display: flex;
    flex: 1;
    font-size: 18px;
    font-weight: 700;
}

.title__icon {
    background: #A094ED;
    border-radius: 6px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
}

.title__btn {

}

.infomation {
    background: #F7F8F9;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    flex-direction: row;
    padding: 16px;
}

.infomation__item {
    display: block;
    font-size: 14px;
    font-weight: 400;
}

.infomation__label {
    color: #657786;
    font-size: 12px;
    font-weight: 400;
}

.infomation__block {
    margin-right: 32px;
}

.infomation__item--blue {
    color: #006FB6;
}

.infomation__item--link {
    cursor: pointer;
    color: #006FB6;
}

.content {
    display: flex;
}

.content__left {
    width: calc(70% - 16px);
    margin-right: 16px;
}

.content__right {
    width: 30%;
}

.separate {
    border-bottom: 1px solid #D0D6E0;
    margin: 16px -16px;
}

.subbox__separate {
    border-bottom: 1px solid #D0D6E0;
}

.activitiesTab {
    border: 1px solid #D0D6E0;
    border-radius: 6px;
}

.input {
    margin-bottom: 16px;
}

.input--right {
    text-align: right;
}

.note {
    font-size: 14px;
    flex-basis: 33.33%;
    margin-bottom: 16px;
    max-width: 33.33%;
    padding-right: 16px;
    word-break: break-all;
}

.note--underline {
    border-bottom: 1px solid #E6ECF0;
    margin-bottom: 8px;
    padding-bottom: 5px;
    padding-right: 0px;
    max-width: 100%;
}

.modified {
    color: #657786;
    font-size: 12px;
    margin-bottom: 4px;
}

.link {
    cursor: pointer;
    color: #006FB6;
}

.link--note {
    font-size: 14px;
}

.link--attachment {
    font-size: 13px;
    font-weight: 400;
}

.showmore {
    text-align: center;
}

.subbox__showmore {
    text-align: center;
    padding: 12px;
}

.link--showmore {
    font-size: 14px;
    font-weight: 500;
}

.boxtitle {
    display: flex;
    justify-content: space-between;
}

.boxtitle__icon {
    border: 1px solid #D0D6E0;
    border-radius: 4px;
    padding: 2px;
    cursor: pointer;
}

.attachment {
    align-items: center;
    display: flex;
    flex-basis: 33.33%;
    margin-bottom: 16px;
}

.attachment__icon {
    margin-right: 8px;
}

.attachment--underline {
    border-bottom: 1px solid #E6ECF0;
    margin-bottom: 16px;
    padding-bottom: 5px;
}

.subbox {
    border:1px solid #D0D6E0;
    border-radius: 6px;
    margin-bottom: 16px;
}

.subbox__header {
    background: #F5F6F7;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    font-size: 16px;
    font-weight: 700;
}

.subbox__content {
    padding: 16px 16px 0;
    display: flex;
    flex-wrap: wrap;
}

.collapsebox {
    display: flex;
    flex-wrap: wrap;    
    font-weight: 400;
    font-size: 14px;
    justify-content: space-between;
    padding: 0 8px;
}

.collapsebox__item {
    border-bottom: 1px solid #E6ECF0;
    flex: 1 1 50%;
    max-width: 48%;
    padding-bottom: 5px;
    margin-bottom: 16px;
}

.collapsebox__item--full {
    border-bottom: 1px solid #E6ECF0;
    margin-bottom: 16px;
    max-width: none;
}

.collapsebox__label {
    color: #657786;
    font-size: 12px;
}

.modified_time { 
    margin-right: 8px;
}