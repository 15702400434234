.header {
    align-items: center;
    background: #F5F6F7;
    border-radius: 6px;
    cursor: pointer;    
    display: flex;
    padding: 8px;
    margin-bottom: 16px;
    font-size: 13px;
    font-weight: 500;
}

.icon {
    margin: 0 12px 0 4px;
}

.title {
    flex: 1;
}

.optional {

}

.children {
    overflow: hidden;
    transition: height 0.4s linear;
}