@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3b4144;
}

/* Global css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

    /*Scrollbar*/
    *::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }


    *::-webkit-scrollbar-track {
        background: #f1f1f1;
    }


    *::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 8px;
    }


    *::-webkit-scrollbar-thumb:hover {
            background: #555;
    }
